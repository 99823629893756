import React from 'react'	
import PropTypes from 'prop-types'	
import { graphql } from 'gatsby'	
import Layout from '../components/Layout'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Content, { HTMLContent } from '../components/Content'

export const ProjectPageTemplate = ({	
  content,
  contentComponent,
  featuredimage,	
  title,
  heading,
  description,
  inProgress,	
  image1,
  image2,
  image3,
  videourl
}) => {
  const PostContent = contentComponent || Content

  return (
  <div className="content">	
    <div	
      className="full-width-image-container margin-top-0"	
      style={{	
        backgroundPosition: `center`,
        backgroundImage: `url(${	
          !!featuredimage?.childImageSharp ? featuredimage.childImageSharp.fluid.src : featuredimage	
        })`,	
      }}	
    >	
    {inProgress ? (
    <div className="ribbon">
        <span>In progress</span>
    </div>
    ) : null}
    </div>	
    <section className="section section--gradient ">	
      <div className="container">
          <div className="columns">	
            <div className="column is-10 is-offset-1">	
              <h2	className="has-text-weight-bold is-size-1">	
                {title}	
              </h2>	
              <h5 className="has-text-weight-semibold is-size-5">{heading}</h5>	
              {/* <p>{description}</p>	 */}
              <PostContent content={content} />
              {videourl && videourl != "" ? (
              <div className="video-wrapper">
              <iframe width="560" height="315" src={videourl} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>   
              ) : null}        
            </div>
          </div>	
          <div className="columns">	
            <div className="column is-10 is-offset-1">
              { <div className="tile is-ancestor">	
                <div className="tile is-vertical">	
                  <div className="tile">	
                    <div className="tile is-parent is-vertical">	
                      <article className="tile is-child">	
                        <PreviewCompatibleImage imageInfo={image1} />	
                      </article>	
                    </div>	
                    {image2 && image2 != "" ? (
                    <div className="tile is-parent">	
                      <article className="tile is-child">	
                        <PreviewCompatibleImage imageInfo={image2} />	
                      </article>	
                    </div>	
                  ) : null}    
                  {image2 && image2 != "" ? ( 	
                  <div className="tile is-parent">	
                    <article className="tile is-child">	
                      <PreviewCompatibleImage imageInfo={image3} />	
                    </article>	
                  </div>
                  ) : null} 	
                </div>	
                </div>
              </div> }
            </div>	
          </div>	
        </div>
    </section>	
  </div>	
)	
}

ProjectPageTemplate.propTypes = {	
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  image: PropTypes.oneOfType([PropTypes.object]),	
  title: PropTypes.string,	
  heading: PropTypes.string,	
  description: PropTypes.string,	
  intro: PropTypes.shape({	
    blurbs: PropTypes.array,	
  }),	
  main: PropTypes.shape({	
    heading: PropTypes.string,	
    description: PropTypes.string,	
    image1: PropTypes.oneOfType([PropTypes.object]),	
    image2: PropTypes.oneOfType([PropTypes.object]),	
    image3: PropTypes.oneOfType([PropTypes.object]),	
  }),	
  testimonials: PropTypes.array,	
  fullImage: PropTypes.oneOfType([PropTypes.object]),	
  pricing: PropTypes.shape({	
    heading: PropTypes.string,	
    description: PropTypes.string,	
    plans: PropTypes.array,	
  }),	
}	

const ProjectPage = ({ data }) => {	
  const { frontmatter, html } = data.markdownRemark

  return (	
    <Layout>	
      <ProjectPageTemplate	
        content={html}
        contentComponent={HTMLContent}
        featuredimage={frontmatter.featuredimage}	
        image1={frontmatter.image1}	
        image2={frontmatter.image2}	
        image3={frontmatter.image3}	
        title={frontmatter.title}	
        heading={frontmatter.heading}	
        description={frontmatter.description}	
        intro={frontmatter.intro}	
        videourl={frontmatter.videourl}
        inProgress={frontmatter.inProgress}
      />	
    </Layout>	
  )	
}	

ProjectPage.propTypes = {	
  data: PropTypes.shape({	
    markdownRemark: PropTypes.shape({	
      frontmatter: PropTypes.object,	
    }),	
  }),	
}	

export default ProjectPage	

export const ProjectPageQuery = graphql`	
  query ProjectPage($id: String!) {	
    markdownRemark(id: { eq: $id }) {	
      html
      frontmatter {	
        title	
        inProgress
        isFeatured
        featuredimage {	
            childImageSharp {	
              fluid(maxWidth: 1920, quality: 100) {	
                ...GatsbyImageSharpFluid	
              }	
            }	
          }	
        heading	
        description	
        videourl
        image1 {	
          childImageSharp {	
            fluid(maxWidth: 1920, quality: 100) {	
              ...GatsbyImageSharpFluid	
            }	
          }	
        }	
        image2 {	
          childImageSharp {	
            fluid(maxWidth: 1920, quality: 100) {	
              ...GatsbyImageSharpFluid	
            }	
          }	
        }	
        image3 {	
          childImageSharp {	
            fluid(maxWidth: 1920, quality: 100) {	
              ...GatsbyImageSharpFluid	
            }	
          }	
        }	
        
      }	
    }	
  }	
`